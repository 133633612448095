import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalEdit: false,
        video: {
            video_id: '',
            video_nama: '',
            video_ket: '',
            video_url: '',
            video_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalEdit = value
        },
        viewModal(state, value) {
            state.video = value
        }
    }
})
