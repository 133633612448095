<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title 
          >Detail Video</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="px-5 py-5">
          <v-row>
            <v-col cols="12" md="12" class="my-auto mb-n5">
              <v-col cols="12" v-if="!viewItem.video_url">
                <v-card
                  class="d-flex align-center justify-center pa-5"
                  outlined
                >
                  <div>
                    <v-icon>mdi-video</v-icon>
                    <span>Preview Video</span>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" v-else>
                <v-card class="d-flex align-center justify-center" outlined>
                  <div>
                    <iframe height="300" width="860" :src="viewItem.video_url">
                    </iframe>
                  </div>
                </v-card>
              </v-col>
            </v-col>

            <v-col cols="12" md="12">
              <!-- URL Video -->
              <v-col cols="12" class="mb-n8">
                <span class="subtitle-2">URL Video</span>
                <v-text-field
                  placeholder="Masukan URL Video Youtube"
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  readonly
                  v-model="viewItem.video_url"
                ></v-text-field>
              </v-col>

              <!-- Nama Video -->
              <v-col cols="12" class="mb-n8">
                <span class="subtitle-2">Nama Video</span>
                <v-text-field
                  dense
                  flat
                  outlined
                  class="mt-2"
                  maxlength="30"
                  autocomplete="off"
                  readonly
                  v-model="viewItem.video_nama"
                ></v-text-field>
              </v-col>

              <!-- Deskripsi Video -->
              <v-col cols="12">
                <span class="subtitle-2">Deskripsi Video</span>
                <v-textarea
                  dense
                  flat
                  outlined
                  class="mt-2"
                  maxlength="250"
                  autocomplete="off"
                  readonly
                  v-model="viewItem.video_ket"
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import modalView from "@/store/video/modalView";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.video;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    CWidth: "70%",
  }),

  methods: {
    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
