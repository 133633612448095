<template>
  <v-dialog v-model="ModalAdd" :width="CWidth">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Tambah Video</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <v-row>
            <v-col cols="12" md="12" class="my-auto mb-n5">
              <v-col cols="12" v-if="!video_url">
                <v-card
                  class="d-flex align-center justify-center pa-5"
                  outlined
                >
                  <div>
                    <v-icon>mdi-video</v-icon>
                    <span>Preview Video</span>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" v-else>
                <v-card class="d-flex align-center justify-center" outlined>
                  <div>
                    <iframe height="300" width="860" :src="video_url"> </iframe>
                  </div>
                </v-card>
              </v-col>
            </v-col>

            <v-col cols="12" md="12">
              <!-- URL Video -->
              <v-col cols="12" class="mb-n8">
                <span class="subtitle-2">URL Video</span>
                <v-text-field
                  placeholder="Masukan URL Video Youtube"
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  v-model="video_url"
                ></v-text-field>
              </v-col>

              <!-- Nama Video -->
              <v-col cols="12" class="mb-n8">
                <span class="subtitle-2">Nama Video</span>
                <v-text-field
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  maxlength="30"
                  v-model="video_nama"
                ></v-text-field>
              </v-col>

              <!-- Deskripsi Video -->
              <v-col cols="12">
                <span class="subtitle-2">Deskripsi Video</span>
                <v-textarea
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  maxlength="250"
                  v-model="video_ket"
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn
              v-if="btnLoading"
              small
              color="primary"
              depressed
              @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else small color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/video/viewVideo";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    ModalAdd: false,
    btnLoading: true,
    CWidth: "70%",

    video_nama: "",
    video_ket: "",
    video_url: "",
  }),

  methods: {
    default() {
      this.video_nama = "";
      this.video_url = "";
      this.video_ket = "";
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.ModalAdd = true;
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },

    async add() {
      this.btnLoading = false;

      const data = {
        video_nama: this.video_nama,
        video_ket: this.video_ket,
        video_url: this.video_url,
      };

      const url = process.env.VUE_APP_API_BASE + "video";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.default();
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.default();
          this.closeModal();
        });
    },
  },
};
</script>
